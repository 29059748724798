import styled from 'styled-components';

import { FlexibleLinkComponent } from 'components/Links';
import { getDimension } from 'styled/helpers/getDimension';

export const BlogHeaderLink = styled(FlexibleLinkComponent)`
  h1,
  h2 {
    margin-bottom: 0;
    margin-top: ${getDimension('size6')};
  }

  &:hover,
  &:focus,
  &:focus-within {
    color: ${props => props.theme.colors.black};
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    h1,
    h2 {
      margin-top: 0;
    }
  }
`;
