import styled, { css } from 'styled-components';

import { FlexibleLinkComponent } from 'components/Links';

// Do not pass these through to the DOM; StyledComponents/React thinks they should go, they shouldn't.
const omitFromDomProps = ['showActive'];

export const MenuLink = styled(FlexibleLinkComponent).withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) => {
    if (omitFromDomProps.includes(prop)) return false;
    return defaultValidatorFn(prop); // Must still call this…
  },
})<{ showActive?: Boolean }>`
  color: ${props => props.theme.colors.black};
  text-decoration: none;
  font-weight: ${props => props.theme.fontWeight.normal};
  line-height: 2;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    max-width: 100%;
  }

  ${props =>
    props.showActive &&
    css`
      color: ${props.theme.colors.black};
      font-weight: ${props.theme.fontWeight.bold};
      border-bottom: 2px solid ${props.theme.colors.orange};
    `};

  &:focus,
  &:focus-within,
  &:hover {
    border-bottom: 2px solid ${props => props.theme.colors.orange};
    color: ${props => props.theme.colors.black};
  }
`;
