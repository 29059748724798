import { useCallback, useState } from 'react';
import { useExitIntent as useReactExitIntent } from 'use-exit-intent';
import { useCookiePreferences } from 'components/CookiePreferences/CookiePreferencesProvider';
import { useCookies } from 'hooks/useCookies';

export const useExitIntent = (id: string) => {
  const cookiePreferences = useCookiePreferences();
  const { cookies } = useCookies();
  const [showOverlay, setShowOverlay] = useState(false);
  const { registerHandler, unsubscribe } = useReactExitIntent({
    cookie: {
      key: `exit-intent-${id}`,
      daysToExpire: 30,
    },
    mobile: {
      triggerOnIdle: false,
    },
  });

  const handleOpen = useCallback(() => {
    setShowOverlay(true);
  }, [setShowOverlay]);

  const handleClose = useCallback(() => {
    setShowOverlay(false);

    /*
     * This doesn't set the exit-intent-blog cookie when functionality cookies haven't been accepted
     * Note: because cookie preferences are set for au/nz locale, this also doesn't set the cookie for au/nz locales
     */
    if (cookiePreferences?.functionality) {
      unsubscribe();
    }
  }, [setShowOverlay, unsubscribe, cookiePreferences?.functionality]);

  registerHandler({
    id: `openModal-${id}`,
    handler: handleOpen,
  });

  // Doesn't show exit intent pop up for existing sharesight users
  // eslint-disable-next-line no-underscore-dangle
  if (cookies?._sharesight_sess) {
    setShowOverlay(false);
  }

  return { handleClose, showOverlay, handleOpen };
};
