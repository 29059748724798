import { useEffect } from 'react';
import { useScript } from 'hooks/useScript';

import type { FC } from 'react';

const globalListId: string = 'r/58/BD4/ACC/5C6AB96D05E5929A';
const sharesight20ListId: string = 'r/1A/20C/EE2/9D2A68783B6E90442540EF23F30FEDED';

export const CampaignMonitorButton: FC<{ isSharesight20?: boolean }> = ({
  isSharesight20 = false,
}) => {
  useScript(`https://btn.createsend1.com/js/sb.min.js?v=3`, 'createsend-script');

  const dataListId = isSharesight20 ? sharesight20ListId : globalListId;

  useEffect(
    () => () => {
      /*
       * ⚠️ MAJOR HACK, this may break at random.
       * On cleanup, we delete some artbirary magical window property that createsend uses to control renders.
       * This allows us to re-render the next time we load the script (on the same page).
       *
       * This `A8717625BBF7422791C7DAB56A977FD8` could be random or change for all we know.
       *
       * Basically, createsend only allows rendering once, but as we navigate across pages we mount and unmount multiple times.
       */

      // @ts-expect-error
      delete window.A8717625BBF7422791C7DAB56A977FD8;
    },
    []
  );

  return (
    <div className="campaign_monitor">
      <div className="createsend-button" data-listid={dataListId} />
    </div>
  );
};
