import { useMemo } from 'react';

import { ExitIntentOverlay } from 'components/ExitIntentOverlay';
import { useExitIntent } from 'hooks/useExitIntent';
import { Layout } from 'components/Layout';
import { Seo } from 'components/Seo';
import { PaginationButtons } from 'components/PaginationButtons';
import { Container } from 'components/Container';
import { BlogHeroContainer } from 'components/Blog';
import { CampaignMonitorButton } from 'components/CampaignMonitorButton';
import { getParsedBlogPost } from 'helpers/getParsedBlogPost';
import { BlogPreviewCards } from 'components/PreviewCards/BlogPreviewCards';

import { getDetails } from 'components/Blog/getDetails';
import { HeroPreviewCard } from 'components/PreviewCards/HeroPreviewCard';
import { FlexContainer } from 'components/FlexContainer';
import type { FC } from 'react';
import type { LocalizedPageProps } from '@sharesight/gatsby-plugin-sharesight-localization';
import type { ContentfulBlogPostRaw } from 'types/blog/blogPost';
import type { BlogCategory as BlogCategoryInterface } from 'types/blog/category';

const BlogCategory: FC<
  LocalizedPageProps & {
    pageContext: {
      entries: ContentfulBlogPostRaw[];
      prevPath?: string;
      nextPath?: string;
      categories: BlogCategoryInterface[];
    };
  }
> = ({ pageContext, path }) => {
  const { showOverlay, handleClose } = useExitIntent('blog');

  const [heroPost, posts] = useMemo(() => {
    const parsed = pageContext.entries.map(post => getParsedBlogPost(post));
    const [hero, ...rest] = parsed;

    return [hero, rest];
  }, [pageContext.entries]);

  const details = getDetails({
    author: heroPost.author,
    date: heroPost.date,
  });
  return (
    <Layout>
      {showOverlay && <ExitIntentOverlay handleClose={handleClose} />}
      <Seo prevPath={pageContext.prevPath} nextPath={pageContext.nextPath} />
      <BlogHeroContainer currentPage={path} categories={pageContext.categories} isMainHeader />

      <Container bottomPadding={6} manageLayout={false} skipColoration>
        <HeroPreviewCard
          categories={heroPost.categories}
          title={heroPost.title}
          subtitle={details}
          urlSlug={heroPost.urlSlug}
          description={heroPost.description}
          image={heroPost.image}
        />
      </Container>

      <Container padding={2} manageLayout={false} skipColoration>
        <FlexContainer justifyContent="space-between">
          <h4>Latest posts</h4>
          <CampaignMonitorButton />
        </FlexContainer>
      </Container>

      <Container padding={2} manageLayout={false} skipColoration>
        <BlogPreviewCards entries={posts} />

        <PaginationButtons prevPath={pageContext.prevPath} nextPath={pageContext.nextPath} />
      </Container>
    </Layout>
  );
};

export default BlogCategory;
