import { useMemo } from 'react';
import { CategoryMenuWrapper } from 'components/Blog/CategoryMenuWrapper.styled';

import { MenuContainer } from './MenuContainer.styled';
import { MenuLink } from './MenuLink.styled';
import { MenuList } from './MenuList.styled';
import { MenuItem } from './MenuItem.styled';

import type { FC } from 'react';
import type { BlogCategory } from 'types/blog/category';

interface CategoryMenuProps {
  currentPage: string;
  categories: BlogCategory[];
  hideCategoryMenu: boolean;
}

export const CategoryMenu: FC<CategoryMenuProps> = ({
  currentPage,
  categories,
  hideCategoryMenu,
}) => {
  const MenuItems = useMemo(
    () =>
      categories.map(category => (
        <MenuItem key={category.urlSlug}>
          <MenuLink to={category.urlSlug} showActive={!!currentPage.includes(category.urlSlug)}>
            {category.name}
          </MenuLink>
        </MenuItem>
      )),
    [currentPage, categories]
  );

  return (
    <CategoryMenuWrapper hideCategoryMenu={hideCategoryMenu} aria-label="category-menu-wrapper">
      <MenuContainer padding={6} background="cream" gap={2}>
        <nav>
          <MenuList>{MenuItems}</MenuList>
        </nav>
      </MenuContainer>
    </CategoryMenuWrapper>
  );
};
